<template>
  <div class="container">
    <div class="model-selection">
      <label for="model">Select Model Type:</label>
      <select v-model="selectedModelType" id="model" class="model-select">
        <option v-for="modelType in modelTypes" :key="modelType">{{ modelType }}</option>
      </select>
    </div>

    <div class="model">
      <ul class="unit-list">
        <li v-for="(unit, index) in units" :key="index" class="unit" :class="{ 'occupied': unit.occupied, 'empty': !unit.occupied }">
          <div class="house-logo">
            <img :src="unit.occupied ? occupiedHouseIcon : emptyHouseIcon" alt="house">
            <p class="unit-name">{{ unit.name }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedModelType: '',
      modelTypes: ['A', 'B', 'C', 'D', 'E', 'F'],
      units: [],
      occupiedHouseIcon: require('@/assets/images/address.png'),
      emptyHouseIcon: require('@/assets/images/free-home-icon-2502-thumb.png')
    };
  },
  watch: {
    selectedModelType() {
      this.generateUnits();
    }
  },
  methods: {
    generateUnits() {
      const totalUnits = 100; // Total number of units for each model
      const occupiedUnits = this.getOccupiedUnits(); // Units that are occupied

      // Generate units based on selected model type
      this.units = [];
      for (let i = 1; i <= totalUnits; i++) {
        const unitName = 'Unit ' + i;
        const occupied = occupiedUnits.includes(i);
        this.units.push({ name: unitName, occupied });
      }
    },
    getOccupiedUnits() {
      // Different models may have different occupied units
      switch (this.selectedModelType) {
        case 'A':
          return [2, 5, 7, 10, 13, 15, 20, 22, 25, 28, 30, 33, 36, 40, 45, 48, 50, 55, 60, 62, 65, 68, 70, 73, 75, 80, 82, 85, 88, 90, 93, 95, 98];
        case 'B':
          return [3, 6, 9, 12, 14, 18, 21, 23, 26, 29, 31, 34, 38, 42, 47, 49, 53, 57, 61, 63, 67, 69, 72, 74, 77, 79, 83, 87, 91, 94, 97];
        case 'C':
          return [1, 4, 8, 11, 16, 17, 19, 24, 27, 32, 35, 39, 43, 44, 46, 51, 52, 54, 56, 58, 59, 64, 66, 71, 76, 78, 81, 84, 86, 89, 92, 96, 99, 100];
        case 'D':
          return [2, 5, 7, 10, 13, 15, 20, 22, 25, 28, 30, 33, 36, 40, 45, 48, 50, 55, 60, 62, 65, 68, 70, 73, 75, 80, 82, 85, 88, 90, 93, 95, 98];
        case 'E':
          return [3, 6, 9, 12, 14, 18, 21, 23, 26, 29, 31, 34, 38, 42, 47, 49, 53, 57, 61, 63, 67, 69, 72, 74, 77, 79, 83, 87, 91, 94, 97];
        case 'F':
          return [1, 4, 8, 11, 16, 17, 19, 24, 27, 32, 35, 39, 43, 44, 46, 51, 52, 54, 56, 58, 59, 64, 66, 71, 76, 78, 81, 84, 86, 89, 92, 96, 99, 100];
        default:
          return [];
      }
    }
  },
  mounted() {
    // Initialize units
    this.generateUnits();
  }
};
</script>

<style scoped>
.container {
  text-align: center;
}

.model-selection {
  margin-bottom: 20px;
}

.unit-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.unit {
  width: 80px;
  height: 80px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8em;
}

.house-logo img {
  width: 50px;
  height: 50px;
}

.house-logo .unit-name {
  margin-top: 5px;
}

.occupied .house-logo img {
  color: green;
  filter: grayscale(0%);
}

.empty .house-logo img {
  filter: grayscale(100%);
  opacity: 0.6;
}

.model-select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
</style>
